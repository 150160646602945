import React, { useContext, useRef, useState } from "react";
import styled from "@emotion/styled";

import SelectLanguage from "./SelectLanguage";

import Hamburger from "hamburger-react";
import LogoWhite from "../../assets/img/landing/EduTest-white.svg";
import useOnClickOutside from "../../utils/useClickOutside";
import { AppContext } from "../../context/Context";
import ProfileMenu from "./ProfileMenu";
import CurrentSubscription from "./CurrentSubscription";
function Navbar({ isMenu, handleDrawer, isMenuMobile, handleDrawerMobile }) {
  const [isLanguage, setIsLanguage] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const { user, lang, setLang, isSideBarOpen } = useContext(AppContext);

  const ref = useRef();
  const tooltipRef = useRef();
  useOnClickOutside(ref, tooltipRef, () => setIsLanguage(false));

  const ref2 = useRef();
  const tooltipRef2 = useRef();
  useOnClickOutside(ref2, tooltipRef2, () => setIsProfile(false));
  return (
    <Wrapper>
      <DeskHamb>
        <Hamburger
          rounded
          size={18}
          toggled={isMenu}
          color="white"
          toggle={handleDrawer}
        />
      </DeskHamb>
      <MobileHamb>
        <Hamburger
          rounded
          size={18}
          toggled={isMenuMobile}
          color="white"
          toggle={handleDrawerMobile}
        />
      </MobileHamb>
      {/*{isMenu || isSideBarOpen ? null : (*/}
      {/*  // <IconLogo fill="#fff" />*/}
      {/*  <ImgWrapper>*/}
      {/*    <img src={LogoWhite} style={{ marginLeft: 12 }} />*/}
      {/*  </ImgWrapper>*/}
      {/*)}*/}
      <Container>
        <UserWrapper>
          {/* <CurrentSubscription /> */}
          <SelectLanguage
            ref={ref}
            tooltipRef={tooltipRef}
            isLanguage={isLanguage}
            setIsLanguage={setIsLanguage}
            lang={lang}
            setLang={setLang}
          />
          <DividerV />
          <div style={{ position: "relative" }}>
            <ProfileMenu
              ref={ref2}
              tooltipRef={tooltipRef2}
              isProfile={isProfile}
              setIsProfile={setIsProfile}
              userName={user?.displayName}
              //   lang={lang}
              //   setLang={setLang}
            />
          </div>
        </UserWrapper>
      </Container>
    </Wrapper>
  );
}

export default Navbar;

const Wrapper = styled.div`
  /* width: calc(100vw - 220px); */
  background: white;
  background-color: #3750ac;
  height: 64px;
  display: flex;
  /* flex-direction: row;
  justify-content: space-between; */

  padding: 0 12px;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.12);
  margin-bottom: 20px;

  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 0 8px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  margin-left: auto;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const DividerV = styled.div`
  background: #919eab3d;
  height: 24px;
  width: 2px;
  margin: auto;
`;

const ImgWrapper = styled.div`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const DeskHamb = styled.div`
  @media screen and (max-width: 501px) {
    display: none;
  }
`;

const MobileHamb = styled.div`
  @media screen and (min-width: 500px) {
    display: none;
    background: red;
  }
`;
